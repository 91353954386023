<template>
    <label
        :class="{ 'has-label-text': hasLabelText, [location]: true }"
    >
        <div class="compare-checkbox">
            <input
                type="checkbox"
                name="compare"
                :checked="active"
                @change="changeCompareData($event)"
            >
            <div class="checkmark has-tooltip ">
                <span class="tooltip left">{{ $t('page.product.compareTooltip') }}</span>
                <b-button
                    variant="link"
                    class="icon sm"
                    :aria-label="$t('page.product.compareTooltip')"
                >
                    <square-icon />
                </b-button>
                <check-icon class="sm" />
            </div>
        </div>
        <div
            v-if="hasLabelText"
            class="text"
        >
            {{ $t('page.product.compareLabel') }}
        </div>
    </label>
</template>

<script>
    import CheckIcon from 'vue-feather-icons/icons/CheckIcon';
    import SquareIcon from 'vue-feather-icons/icons/SquareIcon';
    import { mapGetters, mapMutations, mapState } from 'vuex';
    export default {
        name: 'CompareAction',
        components: {
            CheckIcon,
            SquareIcon,
        },
        props: {
            compareData: {
                type: Object,
                required: true,
            },
            hasLabelText: {
                type: Boolean,
                default: false,
            },
            location: {
                type: String,
                default() {
                    return 'product-card';
                },
            },
        },
        computed: {
            ...mapState('compare', ['toCompareData']),
            ...mapGetters('compare', ['compareCategory']),
            active() {
                let toCompareData = this.toCompareData;
                if (
                    Object.prototype.hasOwnProperty.call(
                        this.compareData,
                        this.compareCategory
                    )
                ) {
                    return Object.prototype.hasOwnProperty.call(
                        toCompareData[this.compareCategory],
                        this.compareData[this.compareCategory].productCode
                    );
                }
                return false;
            },
        },
        methods: {
            ...mapMutations('compare', ['setToCompareData', 'removeFromCompareData']),
            limitReached() {
                let toCompareData = this.toCompareData;
                if (Object.keys(toCompareData).length > 0) {
                    return Object.keys(toCompareData[this.compareCategory]).length == 4;
                }
                return false;
            },
            isFromAnotherCategory() {
                let toCompareCategory = Object.keys(this.compareData)[0];
                return toCompareCategory != this.compareCategory;
            },
            changeCompareData($event) {
                if ($event.target.checked) {
                    if (!this.limitReached() || this.isFromAnotherCategory()) {
                        this.setToCompareData(this.compareData);
                    } else {
                        $event.target.checked = false;
                        this.$toast.open({
                            message: this.$t('notification.compareMaxLength'),
                            duration: 4000,
                            position: 'bottom-right',
                            type: 'info',
                        });
                    }
                } else {
                    this.removeFromCompareData(
                        this.compareData[this.compareCategory].productCode
                    );
                }
            },
        },
    };
</script>

<style scoped lang="scss">
label {
  height: 32px;
  min-width: 32px;
  justify-content: center;
  cursor: pointer;
  .compare-checkbox {
    display: flex;
    align-items: center;
    .checkmark {
      position: relative;
      width: 16px;
      height: 16px;
      &:hover {
        .feather-square {
          stroke: $primary;
        }
      }
      .btn {
        padding: 0;
        margin: 0;
        height: 100%;
        width: 100%;
        &:focus-visible,
        &:focus {
          box-shadow: none;
          border: none !important;
          outline: none;
        }
      }
      svg {
        &.feather-check {
          position: absolute;
          height: 14px;
          width: 14px;
          left: 1px;
          top: 1px;
          opacity: 0;
        }
      }
    }

    input[type='checkbox'] {
      position: absolute;
      opacity: 0;
      cursor: pointer;
      height: 0;
      width: 0;
      &:checked ~ .checkmark {
        .feather-square {
          color: $primary;
          fill: $primary;
          transition: background 100ms ease-out;
        }
        .feather-check {
          opacity: 1;
          color: $white;
        }
      }
    }
  }
  &.has-label-text {
    display: inline-flex;
    margin-bottom: 12px;
    .text {
      margin-left: 8px;
      display: flex;
      align-items: center;
    }
  }
  &.detail {
    height: initial;
  }
}
</style>
