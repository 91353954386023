<template>
    <div
        v-if="canCompare || !isMobile"
        class="actions"
        :class="{ responsive: isMobile && canCompare }"
    >
        <compare-action
            v-if="canCompare"
            :compare-data="compareData"
        />
        <add-to-wishlist
            v-if="!isMobile"
            :product="product"
            location="listing"
            :class="{ single: !canCompare }"
        />
    </div>
</template>

<script>
    import AddToWishlist from './AddToWishlist';
    import CompareAction from './CompareAction';
    export default {
        name: 'CardActions',
        components: {
            CompareAction,
            AddToWishlist,
        },
        props: {
            compareData: {
                type: Object,
                required: true,
            },
            canCompare: {
                type: Boolean,
                default: false,
            },
            isMobile: {
                type: Boolean,
                default: false,
            },
            product: {
                type: Object,
                default() {
                    return {};
                },
            },
        },
    };
</script>

<style scoped lang="scss">
.actions {
  border-radius: 8px 0px;
  background-color: $gray-thin;
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  transition-timing-function: ease-in;
  transition: 300ms;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  label {
    margin: 2px 4px;
  }
  .btn {
    margin: 2px 4px;
    &.single {
      margin: 4px;
    }
    &.active {
      svg {
        fill: $red;
      }
    }
    @include media-breakpoint-up(md) {
      &:hover {
        svg {
          stroke: $red;
        }
      }
    }
    &:focus {
      box-shadow: none;
    }
  }
  &.responsive {
    opacity: 1;
    height: 40px;
    width: 40px;
    justify-content: center;
    background-color: #c6cdd270;
  }
}
</style>
